import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const modules = [
    {
        name: 'employees',
        role: 1,
    },
    // {
    //     name: 'violation',
    //     role: 4,
    // },
    {
        name: 'threat',
        role: 4,
    },
    {
        name: 'problems',
        role: 4,
    },
    {
        name: 'settings',
        role: 1,
    },
];
const Menu = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const { auth } = useAuth()

    return (
        <div className='mx-auto grid place-items-center max-w-7xl gap-y-6 px-4 py-6 sm:grid-cols-2 md:grid-cols-3 sm:gap-8 sm:px-6 sm:py-8 lg:grid-cols-5 lg:px-8 lg:py-12 xl:py-16'>
            {modules.map((module, index) => {
                if (module.role >= auth.user.role) {
                    return <div
                        key={index}
                        onClick={() => {
                            navigate('/menu/' + module.name)
                        }}
                        className="flex flex-col rounded-lg p-3 hover:shadow-md hover:bg-opacity-20 cursor-pointer bg-opacity-10 bg-gray-500 w-full"
                    >
                        <div className="flex md:h-full lg:flex-col lg:text-center">
                            <div className="flex-shrink-0 grid justify-items-center">
                                <span className="inline-flex items-center justify-center sm:h-25 sm:w-25">
                                    <img src={`/logos/general/${module.name}.png`} alt={module.name} className='w-14 h-14 m-3' />
                                </span>
                            </div>
                            <hr className='bg-black' />
                            <div className="ml-4 flex md:flex-1 flex-col justify-center lg:ml-0 lg:mt-1">
                                <div>
                                    <p className="text-base font-medium text-gray-900">
                                        {t(module.name)}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                } else {
                    return null;
                }
            })}
        </div>
    )
}

export default Menu